import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './screens/login/components/login.component';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  CatalogueTasksService,
  Enviroment,
  MaterialModule,
} from '@wp-back-office/core/commons-backoffice';
import { environment, loadEnvironment } from '../environments/environment';

import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  DynamicDialogMessageService,
  DynamicFormModule,
  DynamicSnackBarService,
  DynamicHeaderCardModule,
  DynamicHeaderTableModule,
} from '@wp-back-office/shared/dynamic-components';
import {
  PaletteGeneratorService,
  SharedGenericComponentsModule,
} from '@wp-back-office/shared/generic-components';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  HumanTaskSdkModule,
  ProductsRaterService,
} from '@wp-back-office/core/workflow-sdk';
import { SignInService } from '@wp-back-office/app/user-authentication';
import { rootEffects } from './store/effects/index';
import { EffectsModule } from '@ngrx/effects';
import { InterceptorService } from './services/interceptor.service';
import { Utils } from '@wp-back-office/app/global-information';
import { HttpErrorInterceptorService } from './services/http-error-interceptor.service';
import { rootReducers } from './store/root.reducers';
import { metaReducers } from './store/reducers';
import { CryptoService } from 'libs/app/user-authentication/src/lib/services/crypto.service';
import { EntityDataModule } from '@ngrx/data';
import { CataloguesStore } from 'libs/core/commons-backoffice/src/lib/services/catalogues-store';

/**
 * RecaptchaKeyFactory, retorna la información del recapchat.
 * @returns Enviroment.
 */
export function recaptchaKeyFactory(): string {
  return environment.valueRecaptcha || '';
}

/**
 * Modulo raiz.
 */
@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaV3Module,
    NgxSpinnerModule,
    MaterialModule,
    SharedGenericComponentsModule,
    DynamicFormModule,
    HumanTaskSdkModule,
    DynamicHeaderCardModule,
    DynamicHeaderTableModule,
    NgxPermissionsModule.forRoot(),
    StoreModule.forRoot(rootReducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(rootEffects),
    StoreDevtoolsModule.instrument({
      name: 'tools ngrx',
      maxAge: 100,
      logOnly: environment.production,
      autoPause: true,
    }),
    EntityDataModule.forRoot({}),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => loadEnvironment(),
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: recaptchaKeyFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: Enviroment,
      useValue: environment,
    },
    DynamicSnackBarService,
    SignInService,
    CryptoService,
    CatalogueTasksService,
    CataloguesStore,
    DynamicDialogMessageService,
    PaletteGeneratorService,
    ProductsRaterService,
    Utils,
  ],
})
export class AppModule { }
