import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

const LONGITUD_SAL = 16;
const LONGITUD_VECTOR_INICIALIZACION = LONGITUD_SAL;

/**
 * Convierte un buffer de datos a una cadena Base64.
 * 
 * @param buffer - Buffer de datos a convertir.
 * @returns Cadena codificada en Base64.
 */
const bufferABase64 = (buffer: any) =>
  window.btoa(String.fromCharCode(...new Uint8Array(buffer)));

/**
 * Convierte una cadena en Base64 a un buffer de datos.
 * 
 * @param buffer - Cadena Base64 a convertir.
 * @returns Buffer de datos correspondiente.
 */
const base64ABuffer = (buffer: any) =>
  Uint8Array.from(atob(buffer), c => c.charCodeAt(0));

/**
 * Servicio que intercepta las peticiones HTTP.
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  /**
   * Instancia del servicio de Interseptor.
   */
  constructor() { }

  /**
   * Genera una clave criptográfica derivada de una contraseña y un valor de sal.
   * 
   * @param password - Contraseña base para derivar la clave.
   * @param salt - Valor aleatorio para fortalecer la derivación de clave.
   * @param iterations - Número de iteraciones del algoritmo de derivación.
   * @param length - Longitud de la clave generada (en bits).
   * @param hash - Algoritmo de hash usado en la derivación (e.g., 'SHA-256').
   * @param algorithm - Algoritmo criptográfico utilizado (por defecto, 'AES-CBC').
   * @returns Clave criptográfica derivada.
   */
  public async createKey(
    password: string,
    salt: any,
    iterations: number,
    length: number,
    hash: string,
    algorithm: string = 'AES-CBC'
  ) {
    const encoder = new TextEncoder();
    const importKey = await window?.crypto?.subtle.importKey(
      'raw',
      encoder.encode(password),
      { name: 'PBKDF2' },
      false,
      ['deriveKey']
    );
    return await window?.crypto?.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: encoder.encode(salt),
        iterations: iterations,
        hash,
      },
      importKey,
      { name: algorithm, length: length },
      false,
      ['encrypt', 'decrypt']
    );
  }

  /**
   * Encripta un texto plano con una contraseña proporcionada.
   * 
   * @param contrasena - Contraseña para encriptar los datos.
   * @param textoPlano - Texto a encriptar.
   * @returns Cadena encriptada en Base64.
   */
  public async encrypt(contrasena: string, textoPlano: string) {
    const encoder = new TextEncoder();
    const sal = window.crypto.getRandomValues(new Uint8Array(16));

    const vectorInicializacion = window.crypto.getRandomValues(
      new Uint8Array(16)
    );
    const bufferTextoPlano = encoder.encode(textoPlano);
    const clave = await this.createKey(contrasena, sal, 100000, 256, 'SHA-256');

    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv: vectorInicializacion },
      clave,
      bufferTextoPlano
    );

    const value = bufferABase64([
      ...sal,
      ...vectorInicializacion,
      ...new Uint8Array(encrypted),
    ]);

    return value;
  }

  /**
   * Desencripta un texto encriptado en Base64 con una contraseña proporcionada.
   * 
   * @param password - Contraseña utilizada para desencriptar.
   * @param encriptadoEnBase64 - Texto encriptado en Base64.
   * @returns Texto desencriptado.
   */
  public async decrypt(password: string, encriptadoEnBase64: string) {
    const decoder = new TextDecoder();
    const datosEncriptados = base64ABuffer(encriptadoEnBase64);
    const sal = datosEncriptados.slice(0, LONGITUD_SAL);
    const vectorInicializacion = datosEncriptados.slice(
      0 + LONGITUD_SAL,
      LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION
    );
    const clave = await this.createKey(password, sal, 100000, 256, 'SHA-256');
    const datosDesencriptadosComoBuffer = await window.crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: vectorInicializacion },
      clave,
      datosEncriptados.slice(LONGITUD_SAL + LONGITUD_VECTOR_INICIALIZACION)
    );
    return decoder.decode(datosDesencriptadosComoBuffer);
  }

  /**
   * Guarda un elemento en `localStorage` después de encriptarlo.
   * 
   * @param name - Clave para almacenar el dato.
   * @param item - Dato a almacenar.
   */
  public async setItem(name: string, item: string) {
    const encrypted = await this.encrypt(this.getUser(), `${item}`);
    localStorage.setItem(name, encrypted);
  }

  /**
   * Obtiene un elemento desencriptado desde `localStorage`.
   * 
   * @param name - Clave del dato a recuperar.
   * @returns Dato desencriptado o `null` si no existe.
   */
  public async getItem(name: string): Promise<any> {
    if (!localStorage.getItem(name)) {
      return null;
    }

    const encrypted = await this.decrypt(
      this.getUser(),
      localStorage.getItem(name) || ''
    );
    return encrypted;
  }

  /**
   * Observa un elemento encriptado desde `localStorage`.
   * 
   * @param name - Clave del dato a observar.
   * @returns `Subject` que emite el valor desencriptado.
   */
  public getItemObs(name: string) {
    const subject = new Subject<string>();
    if (!localStorage.getItem(name)) {
      return null;
    }

    this.decrypt(this.getUser(), localStorage.getItem(name) || '')
      .then(value => subject.next(value))
      .catch(err => subject.next(err))
      .finally(() => {
        subject.complete();
        subject.unsubscribe();
      });

    return subject;
  }

  /**
   * Elimina un elemento de `localStorage`.
   * 
   * @param name - Clave del dato a eliminar.
   */
  public removeItem(name: string) {
    localStorage.removeItem(name);
  }

  /**
   * Limpia todo el contenido de `localStorage`.
   */
  public clear() {
    localStorage.clear();
  }

  /**
   * Recupera el identificador del usuario actual desde las cookies.
   * 
   * @returns Identificador del usuario actual.
   */
  private getUser() {
    const name = 'UPLS=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  /**
   * Establece el identificador del usuario en las cookies.
   * 
   * @param user - Identificador del usuario.
   */
  public setUser(user: string) {
    const cookie = document.cookie;
    if (!cookie.includes('UPLS=')) {
      document.cookie = `UPLS=${user}; SameSite=Strict; Secure`;
    }
  }
}
