import { Enviroment } from '@wp-back-office/core/commons-backoffice';
import { GlobalFunction } from '../app/services/global-function.service';

export const environment = {} as Enviroment;

/**
 * Carga el archivo `environment-dev.bin`, lo decodifica desde Base64 y lo asigna al objeto `environment`.
 */
export function loadEnvironment(): Promise<void> {
  const globalFunction = new GlobalFunction();

  return new Promise((resolve, reject) => {
    fetch('assets/secret/environment-qa.bin')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`No se pudo cargar el archivo binario: ${response.statusText}`);
        }
        return response.text();
      })
      .then((base64Content) => {
        if (!globalFunction.isValidBase64(base64Content)) {
          throw new Error('El contenido del archivo no es una cadena Base64 válida.');
        }

        const decodedContent = globalFunction.decodeBase64(base64Content);

        const environmentModule = JSON.parse(decodedContent);

        Object.assign(environment, environmentModule);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}