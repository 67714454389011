// import { ControlConfig } from '@wp-back-office/shared/dynamic-components';
// import { Room, User } from 'libs/app/access-manager/src/lib/store/reducers';

import { ControlConfig } from '../../dynamic-form';

// /**
//  * Modelo de grids angular.
//  */
// export interface Col {
//   /**
//    * Resolucion pequeña.
//    */
//   sm: number;
//   /**
//    * Resolucion pequeña.
//    */
//   lg: number;
//   /**
//    * Resolucion pequeña.
//    */
//   xs: number;
//   /**
//    * Resolucion pequeña.
//    */
//   xl: number;
//   /**
//    * Resolucion pequeña.
//    */
//   md: number;
// }
// /**
//  * Llave de opciones.
//  */
// export interface KeyOptions {
//   /**
//    * Entidad.
//    */
//   entity: string;
//   /**
//    * Entidad.
//    */
//   disableLocal?: boolean;
// }
// /**
//  * Modelo de keyValue con datos adicionales.
//  */
// export interface Option {
//   /**
//    * Codigo o identificador.
//    */
//   code: string;
//   /**
//    * Descripcion o nombre.
//    */
//   description: string;
//   /**
//    * Datos.
//    */
//   data: string;
// }

/**
 * Modelo para formularios tareas pendientes.
 */
export interface Form {
  /**
   * Version.
   */
  version?: string;
  /**
   * Esquemas.
   */
  schema: ControlConfig[];
  /**
   * Descripcion.
   */
  description?: string;
  /**
   *  Tipo.
   */
  type: string;
}

/**
 * ID de formularios de Persona Jurídica.
 */
export type FormTypePJ =
  | 'INITIAL_INFORMATION_PJ'
  | 'LEGAL_FORM_PJ'
  | 'LOCATION_CONTACT_FORM_PJ'
  | 'SEGMENTATION_FORM_PJ'
  | 'ECONOMIC_FORM_PJ'
  | 'FORM_PEP_PJ'
  | 'FORM_PEP_PJ_ADMIN'
  | 'FOREIGN_TRANSACTION_FORM_PJ'
  | 'LEGAL_OPERATION_INTERNATIONAL'
  | 'FORM_LEGAL_REPRESENTATIVE_PJ'
  | 'FORM_PEP_PN'
  | 'FORM_PEP_PN_PERSON'
  | 'FORM_PEP_PN_FAMILY'
  | 'FORM_TRANSACTIONS'
  | 'FORM_PEP_TABLE_PERSON'
  | 'FORM_PEP_TABLE_FAMILY'
  | 'FORM_IDENTIFICATION_PJ'
  | 'FORM_CREATION_SHAREHOLDER_NIT_PJ'
  | 'FORM_CREATION_SHAREHOLDER_NOT_NIT_PJ'
  | 'INITIAL_CO_SIGNER_AND_WORKING_INFORMATION'
  | 'FINAL_CO_SIGNER_AND_WORKING_INFORMATION'
  | 'FORM_PEP_TABLE_FAMILY'
  | 'LEGAL_OPERATION'
  | 'LEGAL_OPERATION_PJ_READONLY'
  | 'PRODUCT_FORM_COMERCIAL_PJ'
  | 'RETURN_REASON_FORM_COMERCIAL_PJ'
  | 'RESPONSE_FORM_COMERCIAL_PJ'
  | 'RESPONSE_FORM_CHANGE_CONDITIONS_PJ'
  | 'RESPONSE_FORM_SARLAFT_PJ'
  | 'RESPONSE_FORM_RISK_PJ'
  | 'CONDITIONS_FORM_PJ'
  | 'FILE_FORM_PJ'
  | 'ADDITIONAL_CREDIT_RELATED_FORM'
  | 'AGREEMENT_THIRD_PARTIES_PAYMENT_FORM_PJ'
  | 'THIRD_PARTIES_PAYMENT_FORM_PJ'
  | 'VIN_FORM_PJ'
  | 'RUNT_FORM_PJ'
  | 'RUNT_VIEW_FORM_PJ'
  | 'RESPONSE_FORM_VIN_BILL_CHARGE_PJ'
  | 'FORM_OLD_CONDITIONS_PJ'
  | 'RESPONSE_FORM_COMERCIAL_APPROVAL_PJ'
  | 'FORM_COINCIDENCES_PJ'
  | 'INSURANCE_ANALYSIS_RESPONSES_PJ'
  | 'FORM_NEW_CONDITIONS_PJ'
  | 'FORM_CREDIT_SUMMARY_PJ'
  | 'RESPONSE_FORM_INSCRIPTION_RUNT_PJ'
  | 'AUTHORIZED_CREDIT_RELATED_FORM_PJ'
  | 'AUTHENTICATION_TYPE_FORM_PJ'
  | 'RESPONSE_FORM_DOCUMENT_AUTH_SIGNING'
  | 'FORM_COINCIDENCES_PJ'
  | 'INSURANCE_ANALYSIS_RESPONSES_PJ'
  | 'FORM_NEW_CONDITIONS_PJ';


// /**
//  * Modelo de usuario creador.
//  */
// export interface UserCreator {
//   /**
//    * Identificador.
//    */
//   sub: string;
//   /**
//    * Nombre de usuario.
//    */
//   username: string;
// }

// /**
//  * Modelo informacion de contacto.
//  */
// export interface ContactInformation {
//   /**
//    * Numero de celular.
//    */
//   mobileNumber: string;
//   /**
//    * Correo electronico.
//    */
//   email: string;
// }

// /**
//  * Modelo de informacion personal.
//  */
// export interface PersonalInformation {
//   /**
//    * Tipo de documento.
//    */
//   documentType: KeyValue;
//   /**
//    * Numero de documento.
//    */
//   documentNumber: string;
//   /**
//    * Lugar de expedicion.
//    */
//   expeditionPlace: string;
//   /**
//    * Nombres.
//    */
//   names: string;
//   /**
//    * Apellidos.
//    */
//   lastNames: string;
//   /**
//    * Lugar de nacimiento.
//    */
//   birthCountry: KeyValue;
//   /**
//    * Departamento.
//    */
//   state: KeyValue;
//   /**
//    * Ciudad.
//    */
//   city: KeyValue;
//   /**
//    * Bandera pep.
//    */
//   PEP: boolean;
// }

// /**
//  * Modelo Informacion del aplicante.
//  */
// export interface Information {
//   /**
//    * Bandera verificaion de email.
//    */
//   hasEmailVerification: boolean;
//   /**
//    * Informacion de contacto.
//    */
//   contactInformation: ContactInformation;
//   /**
//    * Bandera verificacion telefonica.
//    */
//   hasMobileVerification: boolean;
//   /**
//    * Informacion personal.
//    */
//   personalInformation: PersonalInformation;
// }

// /**
//  * Modelo aplicante.
//  */
// export interface Applicant {
//   /**
//    * Tipo de aplicante.
//    */
//   type: KeyValue;
//   /**
//    * Informacion del aplicante.
//    */
//   information: Information;
// }

// /**
//  * Modelo de aplicantes iniciales.
//  */
// export interface InitialApplication {
//   /**
//    * Aplicantes.
//    */
//   applicants: Applicant[];
// }

// /**
//  * Modelo de validaciones.
//  */
// export interface Validations {
//   /**
//    * Validacion de regla De Decision Buro De Credito.
//    */
//   reglaDeDecisionBuroDeCredito: Flags;
//   /**
//    * Validacion de politicas internas.
//    */
//   politicasInternas: Flags;
//   /**
//    * Validacion lista de control rci.
//    */
//   listaDeControlRCI: Flags;
// }

// /**
//  * Modelo de banderas proovedores.
//  */
// export interface Flags {
//   /**
//    * Bandera de registro.
//    */
//   register: boolean;
// }

// /**
//  * Modelo de provedores.
//  */
// export interface Providers {
//   /**
//    * Integracion Buro De Credito Basica.
//    */
//   integracionBuroDeCreditoBasica: Flags;
//   /**
//    * Obtener Cliente Core.
//    */
//   ObtenerClienteCore: Flags;
// }

// /**
//  * Informacion general de la tarea.
//  */
// export interface TaskInformation {
//   /**
//    * Usuario creador.
//    */
//   userCreator: UserCreator;
//   /**
//    * Aplicacion inicial.
//    */
//   initialApplication: InitialApplication;
//   /**
//    * Bandera persona natural.
//    */
//   personaNatural: boolean;
//   /**
//    * Canal.
//    */
//   saleChannel: string;
//   /**
//    * Reglas y validaciones.
//    */
//   validations: Validations;
//   /**
//    * Fecha de creación.
//    */
//   creationDate: number;
//   /**
//    * Tenant identificador.
//    */
//   tenanid: string;
//   /**
//    * Fecha formateada.
//    */
//   dataFormatted: any;
//   /**
//    * Canal identificacion.
//    */
//   chanel: string;
//   /**
//    * Proovedores.
//    */
//   providers: Providers;
//   /**
//    * Aplicantes.
//    */
//   applicants: Applicants[];
//   /**
//    * Información de la tarea.
//    */
//   taskInformation: FormTask;
//   /**
//    * ID de ejecución.
//    */
//   id: string;
//   /**
//    * ID Negocio.
//    */
//   tenantId?: string;
//   /**
//    * Formularios.
//    */
//   forms: Form[];
// }

// /**
//  * Modelo llave valor.
//  */
// export interface KeyValue {
//   /**
//    * Identificador.
//    */
//   code: string;
//   /**
//    * Descripcion o nombre.
//    */
//   description: string;
// }

// /**
//  * Modelo de aplicantes.
//  */
// export interface Applicants {
//   /**
//    * Tipo de aplicante.
//    */
//   type: KeyValue;
//   /**
//    * Tipo de documento.
//    */
//   documentType: KeyValue;
//   /**
//    * Numero de documento.
//    */
//   documentNumber: string;
//   /**
//    * Vendedor.
//    */
//   sellerInfo?: Room;
//   /**
//    * Digito de verificación.
//    */
//   legalStatus: string;
// }

// /**
//  * Modelo de pendientes.
//  */
// export interface FormTask {
//   /**
//    * Identificador.
//    */
//   id: string;
//   /**
//    * Nombre de la tarea.
//    */
//   taskName: string;
//   /**
//    * Informacion de tareas.
//    */
//   taskInformation: TaskInformation;
//   /**
//    * Proceso.
//    */
//   process: string;
//   /**
//    * Fecha de creación.
//    */
//   dateCreated: number;
//   /**
//    * Fecha de expiración.
//    */
//   dateExpiration: string;
//   /**
//    * Aplicantes.
//    */
//   applicants: Applicants[];
//   /**
//    * Formularios.
//    */
//   forms: Form[];
//   /**
//    * Campos editables.
//    */
//   editableFields: any[];
//   /**
//    * Informacion.
//    */
//   payload: any;
//   /**
//    * Usuario creador.
//    */
//   userCreator?: User;
// }
