import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { constantsGeneral } from '@wp-back-office/app/global-information';
import { PaletteGeneratorService } from '@wp-back-office/shared/generic-components';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '@wp-back-office/core/workflow-sdk';
import { Enviroment } from '@wp-back-office/core/commons-backoffice';

/**
 * Componente raiz.
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Titulo.
   */
  public title = 'BackOffice';
  /**
   * Destructor sujeto.
   */
  private destroy$ = new Subject();
  /**
   * Tema activo.
   */
  public activeTheme = '';

  /**
   * Numero de clicks.
   */
  public numberOfClicks;

  /**
   * Crea una instancia de AppComponent.
   * @param titleService - The title service.
   * @param palette - The palette service.
   * @param router - Router.
   * @param storage - Servicio de localStorage encriptado.
   * @param enviroment - Enviroments.
   */
  constructor(
    public titleService: Title,
    private palette: PaletteGeneratorService,
    public router: Router,
    private storage: StorageService,
    private enviroment: Enviroment,
  ) {
    let locationBrowser = '';

    const user = Math.random().toString(36).slice(0);
    this.storage.setUser(user);

    if (window.location.href.includes('/dashboard')) {
      locationBrowser =
        '/dashboard' + window.location.href.split('/dashboard')[1];
    }

    this.numberOfClicks = 0;
    this.titleService.setTitle(constantsGeneral.titleApplication);
    moment.locale('es');

    if (
      localStorage.getItem('A01') &&
      localStorage.getItem(this.enviroment.SESSION_KEY.accessToken) &&
      localStorage.getItem(this.enviroment.SESSION_KEY.idToken) &&
      localStorage.getItem(this.enviroment.SESSION_KEY.refreshToken) &&
      !localStorage.getItem('closeSession') &&
      !window.location.href.includes('/dashboard')
    ) {
      this.router.navigateByUrl(
        locationBrowser.includes('/dashboard')
          ? locationBrowser
          : '/dashboard/home'
      );
    }
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's data-bound properties for the first time.
   */
  public ngOnInit(): void {
    this.palette.applyTheme(undefined);
  }

  /**
   * A callback method that performs custom clean-up, invoked immediately before a directive, pipe, or service instance is destroyed.
   */
  public ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }
}
